<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-if="!api.isLoading && data!=null"
            :items="data"
            :headers="dataHeader"
            :search="search"
            class="elevation-1 mt-4">
            <template v-slot:item.Index="{item}">
                {{ data.indexOf(item)+1 }}
            </template>
            <template v-slot:item.fname="{item}"> 
                <span>
                    {{ item.fname+" "+item.lname }} 
                </span>
            </template>
            <template v-slot:item.attachment_url = {item}>
                <a :href="item.attachment_url" target="_blank" download>
                    {{ item.attachment_url }}
                </a>
            </template>
            <template  v-slot:item.updated_at = {item}>
                <span v-if="item.updated_at !=null">
                    {{ convertTimeZone(item.updated_at) }}
                </span>
            </template>
            <template v-slot:top>
                <v-toolbar width="auto" height="auto" class="py-3" flat>
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3" >
                            <v-row class="mb-3">
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                    List of Excel
                                </v-toolbar-title>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        search:"",
        data:null,
        dataHeader:[
            {
                text:'#',
                value:'Index',
            },
            {
                text:'file name',
                value:'attachment_name',
            },
            {
                text:'file url',
                value:'attachment_url'
            },
            {
                text:'type',
                value:'type',  
            },
            {
                text:'uploaded by',
                value:'fname'
            },
            {
                text:'Uploaded Date',
                value:'updated_at',
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getExcelList") {
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchExcelListApi = this.fetchExcelList();
            this.$api.fetch(fetchExcelListApi);
        },
        fetchExcelList(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel";
            return tempApi;
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
        },
    }

}
</script>